<template>
  <div id="sale-menu" v-click-outside="execute" class="cursor-pointer w-auto">
    <i class="mdi mdi-dots-vertical mdi-18px" @click="menuList"></i>
    <ul
      ref="menu"
      class="bg-white list no-bullets position-absolute px-3 py-2 shadow z-index-999"
    >
      <li
        class="text-sm mb-1 py-1"
        v-if="
          (currentStatus === 1 || currentStatus === 3 || currentStatus === 9 || currentStatus === 7) &&
          (role === 'Admin' || role === 'Branch Admin' || role === 'Branch Admin assistant')
        "
        @click="onOpenPreemption()"
      >
        <i class="mdi mdi-file-pdf mr-2"></i>
        <span class="text-gray-600">ดูใบคำสั่งซื้อ</span>
      </li>

      <li
        class="text-sm mb-1 py-1"
        v-if="
          (currentStatus === 1 || currentStatus === 2 || currentStatus === 3 || currentStatus === 9) && (role === 'Admin' || (role === 'Branch Admin' && branch === '1'))
        "
        @click="onEdit()"
      >
        <i class="mdi mdi-pencil mr-2"></i>
        <span class="text-gray-600">แก้ไขเอกสาร</span>
      </li>

      <li
        class="text-sm mb-1 py-1"
        v-if="
          (currentStatus === 1 || currentStatus === 2) &&
          (role === 'Admin' || role === 'Branch Admin' || role === 'Branch Admin assistant')
        "
        @click="onDelete()"
      >
        <i class="mdi mdi-cancel mr-2"></i>
        <span class="text-gray-600">ยกเลิกเอกสาร</span>
      </li>

      <li
        class="text-sm mb-1 py-1"
        v-if="currentStatus === 2 || currentStatus === 4 || currentStatus === 9"
        @click="onOpenPreemption()"
      >
        <i class="mdi mdi-file-pdf mr-2"></i>
        <span class="text-gray-600">พิมพ์ใบจ่ายสินค้า</span>
      </li>
      <li
        class="text-sm mb-1 py-1"
        v-if="(role === 'Checker' && branch !== 1) &&(currentStatus === 3)"
        @click="onReceive()"
      >
        <i class="mdi mdi-file-pdf mr-2"></i>
        <span class="text-gray-600">รับสินค้า</span>
      </li>
      <li
        class="text-sm mb-1 py-1"
        v-if="((role === 'Branch Admin'&& branch === '1') || role === 'Admin') && (currentStatus === 2 || currentStatus === 3)"
        @click="onLicensePlate()"
      >
        <i class="mdi mdi-tag-outline mr-2"></i>
        <span class="text-gray-600">ระบุทะเบียนรถ</span>
      </li>

      <li
        class="text-sm mb-1 py-1"
        v-if="currentStatus === 3 && role === 'Branch Admin' && branch !== '1'"
        @click="onOpenReceiveOrder()"
      >
        <i class="mdi mdi-file-pdf mr-2"></i>
        <span class="text-gray-600">รับสินค้า</span>
      </li>
      <li
        class="text-sm mb-1 py-1"
        v-if="currentStatus === 1 && ((role === 'Branch Admin') || role === 'Branch Admin assistant')"
        @click="onApprove()"
      >
        <i class="mdi mdi-check mr-2"></i>
        <span class="text-gray-600">ยืนยันคำสั่งซื้อ</span>
      </li>

      <li
        class="text-sm mb-1 py-1"
        v-if="(currentStatus === 1 || currentStatus === 9) && (role === 'Admin' || (role === 'Branch Admin') || role === 'Branch Admin assistant')"
        @click="onReject()"
      >
        <i class="mdi mdi-window-close mr-2"></i>
        <span class="text-gray-600">ยกเลิกคำสั่งซื้อ</span>
      </li>
      <!-- <li
        class="text-sm mb-1 py-1"
        v-if="currentStatus === 6"
        @click="onConfirmPayment()"
      >
        <i class="mdi mdi-file-pdf mr-2"></i>
        <span class="text-gray-600">ยืนยันการชำระเงิน</span>
      </li>

      <li
        class="text-sm mb-1 py-1"
        v-if="currentStatus === 6"
        @click="onRejectPayment()"
      >
        <i class="mdi mdi-file-pdf mr-2"></i>
        <span class="text-gray-600">ปฏิเสธการชำระเงิน</span>
      </li> -->
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  props: {
    id: {
      type: String,
    },
    currentStatus: {
      type: Number,
    },
    currentType: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      role: "",
      branch: "",
      state: false,
    };
  },
  methods: {
    async getRole() {
      const res = await this.$store.dispatch(
        "Auth/getRole",
        {},
        { root: true }
      );
      this.role = res.data.role;
      this.branch = res.data.branch_id;
    },
    execute() {
      this.state = this.state ? !this.state : this.state;
      if (!this.state) {
        this.hideMenu();
      }
    },
    menuList() {
      this.state = !this.state;
      if (this.state) {
        this.showMenu();
      } else {
        this.hideMenu();
      }
    },
    showMenu() {
      const el = this.$refs.menu;
      el.classList.add("animation-menu-list");
    },
    hideMenu() {
      const el = this.$refs.menu;
      el.classList.remove("animation-menu-list");
    },
    onLicensePlate() {
      this.$swal({
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showLoaderOnConfirm: true,
        inputPlaceholder: "ตัวอย่าง กก-1111 (089-9999999)",
        width: "29rem",
        padding: "1.825em",
        html:
          '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
          '<div class="d-flex flex-column">' +
          '<span class="font-bold text-base py-2.5 text-left">กรุณาระบุทะเบียนรถและหมายเลขติดต่อ</span>' +
          "</div>" +
          "</div>",
        customClass: {
          popup: "swal2-custom-rounded",
          closeButton: "text-3xl swal2-custom-close",
          actions: "justify-content-end",
          contant: "d-flex flex-row justify-content-around px-2",
          title: "d-none",
          confirmButton:
            "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton:
            "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
        },
        allowOutsideClick: false,
        focusConfirm: false,
        cancelButtonColor: "#dc4a38",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
        showCancelButton: true,
        showCloseButton: true,
        preConfirm: async (d) => {
          const result = await Vue.prototype.$axios.post(
            `${Vue.prototype.$host}/api/v1/document/preemption/license_plate/${this.id}`, {
              license_plate: d
            }
          );
          return result
        },
      }).then(async (result) => {
        // console.log(result)
        // if (result.value) {
        //   const result = await Vue.prototype.$axios.post(
        //     `${Vue.prototype.$host}/api/v1/document/preemption/approvePayment/${this.id}`
        //   );
        //   if (result.status === 200) window.location.reload();
        // }
      });
    },
    onConfirmPayment() {
      this.$swal({
        width: "29rem",
        padding: "1.825em",
        html:
          '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
          '<div class="d-flex flex-column">' +
          '<span class="font-bold text-base py-2.5 text-left">ท่านต้องการยืนยันการชำระเงินหรือไม่?</span>' +
          '<span class="text-base text-left text-gray-500">หากยืนยันการชำระเงินแล้ว จะไม่สามารถแก้ไขได้</span>' +
          "</div>" +
          "</div>",
        customClass: {
          popup: "swal2-custom-rounded",
          closeButton: "text-3xl swal2-custom-close",
          actions: "justify-content-end",
          contant: "d-flex flex-row justify-content-around px-2",
          title: "d-none",
          confirmButton:
            "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton:
            "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
        },
        allowOutsideClick: false,
        focusConfirm: false,
        cancelButtonColor: "#dc4a38",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
        showCancelButton: true,
        showCloseButton: true,
      }).then(async (result) => {
        if (result.value) {
          const result = await Vue.prototype.$axios.post(
            `${Vue.prototype.$host}/api/v1/document/preemption/approvePayment/${this.id}`
          );
          if (result.status === 200) window.location.reload();
        }
      });
    },
    onRejectPayment() {
      this.$swal({
        width: "29rem",
        padding: "1.825em",
        html:
          '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
          '<div class="d-flex flex-column">' +
          '<span class="font-bold text-base py-2.5 text-left">ท่านต้องการปฏิเสธการชำระเงินหรือไม่?</span>' +
          '<span class="text-base text-left text-gray-500">หากปฏิเสธการชำระเงินแล้ว จะไม่สามารถแก้ไขได้</span>' +
          "</div>" +
          "</div>",
        customClass: {
          popup: "swal2-custom-rounded",
          closeButton: "text-3xl swal2-custom-close",
          actions: "justify-content-end",
          contant: "d-flex flex-row justify-content-around px-2",
          title: "d-none",
          confirmButton:
            "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton:
            "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
        },
        allowOutsideClick: false,
        focusConfirm: false,
        cancelButtonColor: "#dc4a38",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
        showCancelButton: true,
        showCloseButton: true,
      }).then(async (result) => {
        if (result.value) {
          const result = await Vue.prototype.$axios.post(
            `${Vue.prototype.$host}/api/v1/document/preemption/rejectPayment/${this.id}`
          );
          if (result.status === 200) window.location.reload();
        }
      });
    },
    onDelete() {
      this.$swal({
        width: "29rem",
        padding: "1.825em",
        html:
          '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
          '<div class="d-flex flex-column">' +
          '<span class="font-bold text-base py-2.5 text-left">ท่านต้องการยกเลิกเอกสารนี้หรือไม่?</span>' +
          '<span class="text-base text-left text-gray-500">หากยืนยันการยกเลิกแล้ว จะไม่สามารถแก้ไขได้</span>' +
          "</div>" +
          "</div>",
        customClass: {
          popup: "swal2-custom-rounded",
          closeButton: "text-3xl swal2-custom-close",
          actions: "justify-content-end",
          contant: "d-flex flex-row justify-content-around px-2",
          title: "d-none",
          confirmButton:
            "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
          cancelButton:
            "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
        },
        allowOutsideClick: false,
        focusConfirm: false,
        cancelButtonColor: "#dc4a38",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
        showCancelButton: true,
        showCloseButton: true,
      }).then(async (result) => {
        if (result.value) {          
          this.$emit("delete", {
            status: true,
            id: this.id
          })
        }
      })
    },
    onApprove() {
      this.execute();
      this.$emit("menuApproveModal", {
        status: true,
        id: this.id,
        component: "approve-preemption",
      });
    },
    onReject() {
      this.execute();
      this.$emit("menuRejectModal", {
        status: true,
        id: this.id,
        component: "reject-preemption",
      });
    },
    onOpenPreemption() {
      window.open(this.$router.resolve("/order-centric/po/" + this.id).href, "_blank");
    },
    onReceive() {
      this.$router.push("/order-centric/receive/" + this.id);
    },
    onEdit() {
      if (!this.currentType) {
        this.$router.push("/order-centric/po/edit/" + this.id);
      } else {
        this.$router.push("/order-centric/customer/edit/" + this.id);
      }
    },
    onOpenReceiveOrder() {
      this.$router.push("/order-centric/receive/" + this.id);
    },
  },
  mounted() {
    this.getRole();
  },
};
</script>

<style scoped>
#sale-menu {
  position: relative;
}
#sale-menu ul.list {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: fit-content;
  text-align: left;
}
ul.animation-menu-list {
  display: block !important;
  transition: all;
  -webkit-animation: fadeInDown 0.1s linear forwards; /* Chrome, Safari, Opera */
  animation: fadeInDown 0.1s linear forwards;
}

/* Standard syntax */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
</style>
